const HyrosScript = () => (
    <script
        async
        type="text/javascript"
        id="hyros"
        dangerouslySetInnerHTML={{
            __html: `
                var head = document.head;
                var script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = "https://td.livelink.ai/v1/lst/universal-script?ph=b3f6f3afc929450f61629a038445cccffb47cbaff64838ae264c645ea52ee567&tag=!clicked&ref_url=" + encodeURI(document.URL) ;
                head.appendChild(script);
            `,
        }}
    />
)

export default HyrosScript
