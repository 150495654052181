import { Flex, FormControl, FormLabel, Text } from "@chakra-ui/react"
import groupBy from "lodash/groupBy"
import reject from "lodash/reject"
import { useRouter } from "next/router"
import React, { useEffect, useMemo, useState } from "react"

import { LinkedInEvents } from "../@types/analytics"
import {
    EFeatureAccessLevel,
    EPlatformSubscriptionPlan,
    EPlatformSubscriptionPlanFrequency,
    ESubscriptionOption,
} from "../@types/subscription_types"
import _c from "../configs/constants"
import AlertController from "../controllers/alert_controller"
import Analytics from "../controllers/analytics_controller"
import { BaseUser } from "../server/dao/user_dao"
import { linkToPurchasePlatformSubscription, linkToSignup } from "../utils/link_util"
import { hasSnippetPlatformSubscription } from "../utils/user_util"
import PricingCard, { IPricingCardProps } from "./subscriptions/PricingCard"
import { SwitchInput } from "./SwitchInput"

interface IPricingPageProps {
    plans: IPricingCardProps["plans"]
    currentUser?: BaseUser & { token?: string }
}

const PricingSection = ({ plans, currentUser }: IPricingPageProps) => {
    const router = useRouter()

    const [isYearly, setYearly] = useState(true)

    const hasSubscription = !!currentUser && hasSnippetPlatformSubscription(currentUser)

    const activePlans = useMemo(() => {
        const planGroups = groupBy(plans, "interval")
        let allPlans
        if (isYearly) {
            allPlans = [
                planGroups["year"].filter((p) => p.accessLevel === EFeatureAccessLevel.Free),
                planGroups["month"]?.filter((p) => p.accessLevel === EFeatureAccessLevel.Starter),
                planGroups["year"].filter((p) => p.accessLevel === EFeatureAccessLevel.Pro),
            ]
        } else {
            allPlans = [
                planGroups["month"].filter((p) => p.accessLevel === EFeatureAccessLevel.Free),
                planGroups["month"]?.filter((p) => p.accessLevel === EFeatureAccessLevel.Starter),
                planGroups["month"].filter((p) => p.accessLevel === EFeatureAccessLevel.Pro),
            ]
        }
        let visiblePlans = allPlans

        if (currentUser) {
            visiblePlans = reject(allPlans, (group) =>
                group.some((p) => p.accessLevel === EFeatureAccessLevel.Free),
            )
            
        }
        return visiblePlans.filter((list) => !!list && list.length > 0)
    }, [plans, isYearly])

    useEffect(() => {
        if (!router.query.isCreatorSignup) {
            return
        }
        Analytics.trackLinkedInConversion(
            LinkedInEvents.LINKEDIN_BUY_PLATFORM_SUBSCRIPTION_START_EVENT_ID,
        )
    }, [])
    const showStarterAnnualAlert = () => {
        const monthlyStarterPlan = plans.find(
            (p) =>
                p.accessLevel === EFeatureAccessLevel.Starter &&
                p.interval === EPlatformSubscriptionPlanFrequency.Month,
        )!
        AlertController.show({
            title: "Starter plan only available as monthly",
            message: (
                <Text>
                    You are about to subscribe to the <strong>Monthly</strong> Starter plan.
                </Text>
            ),
            primaryAction: {
                label: "Subscribe to Monthly Starter",
                onClick: () => {
                    handleClickPlan(monthlyStarterPlan.id)
                },
            },
            isDismissable: true,
            buttonDirection: "column",
        })
    }

    const handleClickPlan = async (planId: string, action?: ESubscriptionOption) => {
        if (action === ESubscriptionOption.None) {
            return
        }
        if (action === ESubscriptionOption.Register) {
            router.push(linkToSignup())
            return
        }
        if (action === ESubscriptionOption.Cancel) {
            router.push("/account/subscriptions/cancel")
            return
        }
        const shouldSubscribe = !action
        console.log("shouldSubscribe", shouldSubscribe)
        if (shouldSubscribe) {
            const plan = plans.find((p) => p.id === planId)!
            console.log("plan", plan)
            const requiresMonthlyVersion =
                plan.accessLevel === EFeatureAccessLevel.Starter &&
                plan.interval === EPlatformSubscriptionPlanFrequency.Year
            if (requiresMonthlyVersion) {
                return showStarterAnnualAlert()
            }
        }
        let checkoutPath = linkToPurchasePlatformSubscription(
            planId,
            EPlatformSubscriptionPlan.Snippets,
            action,
        )
        if (!!router.query.projectId) {
            checkoutPath += `&projectId=${router.query.projectId}`
        }
        router.push(checkoutPath)
    }

    const renderSwitch = () => {
        if (hasSubscription) {
            return (
                <FormControl
                    display="flex"
                    width="full"
                    justifyContent="center"
                    alignItems="center"
                    gap={5}
                    marginY="15px"
                    userSelect="none"
                >
                    <FormLabel
                        htmlFor="select-period"
                        margin="0"
                        fontWeight="600"
                        fontSize="lg"
                        color={!isYearly ? "#000" : "#868686"}
                    >
                        Monthly
                    </FormLabel>
                    <SwitchInput
                        id="select-period"
                        isChecked={isYearly}
                        onChange={() => setYearly(!isYearly)}
                    />
                    <FormLabel
                        htmlFor="select-period"
                        margin="0"
                        fontWeight="600"
                        fontSize="lg"
                        color={isYearly ? "#000" : "#868686"}
                    >
                        Yearly
                    </FormLabel>
                </FormControl>
            )
        }
        return (
            <FormControl
                display="flex"
                width="full"
                justifyContent="center"
                alignItems="center"
                gap={5}
                marginY="15px"
                userSelect="none"
            >
                <SwitchInput
                    id="select-period"
                    isChecked={isYearly}
                    onChange={() => setYearly(!isYearly)}
                />
                <FormLabel
                    htmlFor="select-period"
                    margin="0"
                    fontWeight="600"
                    fontSize="lg"
                    color="#000000"
                >
                    Apply annual discount
                </FormLabel>
            </FormControl>
        )
    }

    return (
        <Flex direction="column" zIndex={2} width="full">
            {renderSwitch()}
            <Flex
                direction={["column", null, "row"]}
                alignItems="stretch"
                justifyContent="center"
                gap={5}
            >
                {activePlans.map((tierPlans) => (
                    <PricingCard
                        key={`${tierPlans[0].accessLevel}_${tierPlans[0].interval}`}
                        plans={tierPlans}
                        isYearly={isYearly}
                        currentUser={currentUser}
                        onClickPlan={handleClickPlan}
                    />
                ))}
            </Flex>
        </Flex>
    )
}

export default PricingSection
