export enum ESubscriptionStatus {
    ACTIVE = "active",
    PAST_DUE = "past_due",
    UNPAID = "unpaid",
    CANCELED = "canceled",
    INCOMPLETE = "incomplete",
    INCOMPLETE_EXPIRED = "incomplete_expired",
    TRIALING = "trialing",
}

export enum EPlatformSubscriptionStatus {
    Active = "active",
    PastDue = "past_due",
    Trial = "trial",
    Failed = "failed",
    Cancelled = "canceled",
    Upgraded = "upgraded",
    ScheduledDowngrade = "scheduled_downgrade",
    Downgraded = "downgraded",
}

export const ACTIVE_SUBSCRIPTION_STATUSES: ESubscriptionStatus[] = [
    ESubscriptionStatus.ACTIVE,
    ESubscriptionStatus.INCOMPLETE,
    ESubscriptionStatus.TRIALING,
    ESubscriptionStatus.PAST_DUE,
]

export const ACTIVE_PLATFORM_SUBSCRIPTION_STATUSES: EPlatformSubscriptionStatus[] = [
    EPlatformSubscriptionStatus.Trial,
    EPlatformSubscriptionStatus.Active,
    EPlatformSubscriptionStatus.PastDue,
]

export enum EPlatformSubscriptionPlan {
    Main = "main",
    Snippets = "snippets",
}

export enum EPlatformSubscriptionPlanFrequency {
    Month = "month",
    Year = "year",
}

export enum EPlatformSubscriptionType {
    Starter = "snippets - starter",
    Pro = "snippets - pro",
}

export enum EFeatureAccessLevel {
    Free = "free",
    Starter = "starter",
    Pro = "pro",
}

export enum ESubscriptionOption {
    Register = "register",
    Upgrade = "upgrade",
    Downgrade = "downgrade",
    Cancel = "cancel",
    None = "none",
}

export enum ETrialType {
    ThreeDays = "3_days",
    Regular = "7_days",
    NoTrial = "no_trial",
}
