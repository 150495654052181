import { Live } from "@prisma/client"
import queryString from "query-string"

import { ESettingsPage } from "../@types/global_types"
import { EPlatformSubscriptionPlan, ESubscriptionOption } from "../@types/subscription_types"
import { XOR } from "../@types/utility_types"
import _c from "../configs/constants"
import type { BaseCommunity } from "../server/dao/community_dao"
import type { BaseLive } from "../server/dao/live_dao"
import type { BaseUser } from "../server/dao/user_dao"
import { isValidUrl } from "./string_util"

export const linkToCreateSnippets = (
    videoUrl?: string,
    retry?: boolean,
    fullUrl = false,
    isCanonical = false,
) => {
    const path = "/clips"
    return queryString.stringifyUrl(
        {
            url: linkToPath(path, fullUrl, isCanonical),
            query: {
                url: videoUrl ? encodeURIComponent(videoUrl) : null,
                retry: retry ?? null,
            },
        },
        { encode: true, skipNull: true, skipEmptyString: true },
    )
}

export const linkToSnippetProjects = (fullUrl = false, isCanonical = false) => {
    const path = "/clips/projects"
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToSettingsPage = (page: ESettingsPage, fullUrl = false, isCanonical = false) => {
    const path = `/account/settings/${page}`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToEditBrands = (
    clipId: string | null = null,
    fullUrl = false,
    isCanonical = false,
) => {
    let path
    if (!!clipId) {
        path = `/clips/brands/edit?clipId=${clipId}`
    } else {
        path = `/clips/brands/edit`
    }
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToCreator = (user: any, fullUrl = false, isCanonical = false) => {
    const path = `/${user.slug}`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToCreateEvent = (creator: BaseUser, fullUrl = false, isCanonical = false) => {
    const path = `/${creator.slug}/create`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToCourse = (
    creatorSlug: string,
    courseSlug: string,
    fullUrl = false,
    isCanonical = false,
) => {
    const path = `/${creatorSlug}/course/${courseSlug}`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToEditCourse = (
    creator: any,
    course: any,
    fullUrl = false,
    isCanonical = false,
) => {
    const path = `/course/${course.slug}/edit`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToCreatorRecordings = (user: any, fullUrl = false, isCanonical = false) => {
    const path = `/${user.slug}?view=previous`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToCommunityWaitingList = (
    creatorSlug: string,
    fullUrl = false,
    isCanonical = false,
) => {
    const path = `/join-waiting-list?creatorSlug=${creatorSlug}`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToLiveRoom = (
    live: BaseLive,
    token?: string,
    fullUrl = false,
    isCanonical = false,
) => {
    const path = `/${live?.creator.slug!}/${live?.slug}/join${token ? `?token=${token}` : ""}`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToLiveRoomExternal = (live: BaseLive, isHost: boolean) =>
    `${live.room!.url}/${isHost ? "host" : "viewer"}`

export const linkSubscriberToLiveRoom = (
    live: BaseLive,
    token: string,
    fullUrl = false,
    isCanonical = false,
) => {
    const path = `/${live?.creator.slug!}/${live?.slug}/join?subscriber=${token}`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToLiveRoomGeneric = (live: BaseLive, fullUrl = false, isCanonical = false) => {
    const path = `/${live?.creator.slug!}/${live?.slug}/join-email`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToPurchaseLive = (live: BaseLive, fullUrl = false, isCanonical = false) => {
    const path = `/checkout?type=live&slug=${live.slug}`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToPurchaseCourse = (
    course: { slug: string },
    priceId?: string,
    fullUrl = false,
    isCanonical = false,
) => {
    const path = `/checkout?type=course&slug=${course.slug}&priceId=${priceId}`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToPurchaseRecording = (live: BaseLive, fullUrl = false, isCanonical = false) => {
    const path = `/checkout?type=recording&slug=${live.slug}`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToSubscribeToCreator = (
    creator: BaseUser,
    fullUrl = false,
    isCanonical = false,
) => {
    const path = `/checkout?type=subscription&slug=${creator.slug}`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToPurchasePlatformSubscription = (
    planId: string,
    type: EPlatformSubscriptionPlan,
    action?: ESubscriptionOption,
    fullUrl = false,
    isCanonical = false,
) => {
    const path = "/checkout"
    return queryString.stringifyUrl(
        {
            url: linkToPath(path, fullUrl, isCanonical),
            query: {
                type,
                planId,
                action: action ?? null,
            },
        },
        { encode: true, skipNull: true, skipEmptyString: true },
    )
}

export const linkToLiveFeedback = (
    live: BaseLive,
    attendee?: string,
    fullUrl = false,
    isCanonical = false,
) => {
    const path = `/${live?.creator.slug!}/${live?.slug}/feedback?attendee=${attendee}`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToLiveRecording = (
    live: BaseLive,
    recordingId: string,
    token?: string,
    fullUrl = false,
    isCanonical = false,
) => {
    const path = `/live/${live?.slug}/recordings/${recordingId}/watch${
        token ? `?token=${token}` : ""
    }`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkSubscriberToLiveRecording = (
    live: BaseLive,
    recordingId: string,
    token: string,
    fullUrl = false,
    isCanonical = false,
) => {
    const path = `/live/${live?.slug}/recordings/${recordingId}/watch?subscriber=${token}`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkCoursePurchaserToLiveRecording = (
    live: BaseLive,
    recordingId: string,
    token: string,
    fullUrl = false,
    isCanonical = false,
) => {
    const path = `/live/${live?.slug}/recordings/${recordingId}/watch?token=${token}`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToPath = (path: string, fullUrl = false, isCanonical = false) => {
    const prefix = isCanonical ? _c.BASE_URL_CANONICAL : _c.BASE_URL
    return fullUrl ? `${prefix}${path}` : path
}

export const linkToEditLive = (live: Live, fullUrl = false, isCanonical = false) => {
    const path = `/live/${live?.slug}/edit`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToLogin = (
    options: { destination?: string; email?: string } = {},
    fullUrl = false,
    isCanonical = false,
) => {
    const path = "/login"
    return queryString.stringifyUrl(
        {
            url: linkToPath(path, fullUrl, isCanonical),
            query: {
                ...options,
                email: options.email ? encodeURIComponent(options?.email) : null,
            },
        },
        { encode: true, skipNull: true, skipEmptyString: true },
    )
}

export const linkToSignup = (
    options: {
        destination?: string
        email?: string
        magicClaim?: string
        projectId?: string
    } = {},
    fullUrl = false,
    isCanonical = false,
) => {
    const path = "/signup"
    return queryString.stringifyUrl(
        {
            url: linkToPath(path, fullUrl, isCanonical),
            query: options,
        },
        { encode: true, skipNull: true, skipEmptyString: true },
    )
}

export const linkToCommunity = (
    params: XOR<{ creatorSlug: string }, { community: NonNullable<BaseCommunity> }>,
    fullUrl = false,
    isCanonical = false,
) => {
    const creatorSlug = params.creatorSlug ?? params.community.creator.slug
    return linkToPath(`/${creatorSlug}/community`, fullUrl, isCanonical)
}

export const linkToCommunityPost = (
    postId: string,
    creatorSlug?: string,
    channelSlug?: string,
    fullUrl = false,
    isCanonical = false,
) => {
    const path =
        creatorSlug && channelSlug
            ? `/${creatorSlug}/community/${channelSlug}/post/${postId}`
            : `/community/post/${postId}`
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToCommunityChannel = (
    creatorSlug: string,
    channelSlug: string,
    fullUrl = false,
    isCanonical = false,
) => {
    const path = `/${creatorSlug}/community/${channelSlug}`

    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToProject = (
    projectId: string,
    selectedClipId?: string | undefined,
    fullUrl = false,
    isCanonical = false,
) => {
    let path = `/clips/projects/${projectId}`
    if (selectedClipId) {
        path += `?clipId=${selectedClipId}`
    }
    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToEditClip = (clipId: string, fullUrl = false, isCanonical = false) => {
    const path = `/clips/editor?clipId=${clipId}`

    return linkToPath(path, fullUrl, isCanonical)
}

export const linkToCentralRedirect = (
    destination?: string,
    fullUrl = false,
    isCanonical = false,
) => {
    let path = "/redirect-for-role"
    if (destination) {
        path += "?destination=" + encodeURIComponent(destination)
    }
    return linkToPath(path, fullUrl, isCanonical)
}

export const copyToClipboard = async (text: string) => {
    const textToCopy = text
    try {
        navigator.clipboard.writeText(textToCopy)
    } catch (err) {
        console.error("Unable to copy text", err)
    }
}

interface ShareConfig {
    title: string
    url: string
    fallback: () => void
    onShareComplete?: (complete: boolean) => void
}

export const shareUrl = async ({ title, url, fallback, onShareComplete }: ShareConfig) => {
    if (navigator.share) {
        try {
            await navigator.share({
                title,
                url,
            })
            onShareComplete?.(true)
        } catch {
            onShareComplete?.(false)
        }
    } else {
        fallback()
    }
}

export const validateAndFormatLink = (url: string) => {
    if (isValidUrl(url)) {
        if (!/^(https)?:\/\//.test(url)) {
            url = `https://${url}`
        }
        return { isValid: true, url }
    } else {
        return { isValid: false, url }
    }
}

export enum EAWSCFDistribution {
    Snippets = "https://d231m5n9bz59pt.cloudfront.net",
}
const EAWSCFDistributionStaging = {
    [EAWSCFDistribution.Snippets]: "https://d347pn7y6afuwk.cloudfront.net",
}

export const getCloudFrontUrl = (distributionKey: EAWSCFDistribution, objectKey?: string) => {
    let prefix: string = distributionKey
    if (_c.isDevOrStaging) {
        prefix = EAWSCFDistributionStaging[distributionKey]
    }
    if (!objectKey) {
        return prefix
    }
    return prefix + "/" + objectKey
}
