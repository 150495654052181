import type { Live, PlatformSubscription } from "@prisma/client"
import dayjs from "dayjs"

import { ECourseAccessTier } from "../@types/course_types"
import { EContentAccessType } from "../@types/global_types"
import { EPlatformSubscriptionType } from "../@types/subscription_types"
import _c from "../configs/constants"
import type { BaseCourse } from "../server/dao/course_dao"
import type { BaseLive } from "../server/dao/live_dao"
import type { BaseUser } from "../server/dao/user_dao"
import { getCourseAccessRequirements } from "./course_util"
import { sumNumbers } from "./number_util"

export enum EUserProfile {
    CREATOR = "Creator",
    MEMBER = "Member",
}

export const isUserSubscribedToPlatform = (user: { platformSubscriptions: any[] | null }) => {
    return !!user?.platformSubscriptions && user?.platformSubscriptions.length > 0
}

export const isUserSubscribedToCreator = (user: BaseUser, creatorId: string) =>
    user?.subscriptions.some(({ plan }) => plan.creatorId === creatorId)

export const isUserNotVerified = (user: BaseUser) => user.isEmailVerified !== null && !user.isEmailVerified

export const isUserMemberOfCreatorCommunity = (user: BaseUser, creatorSlug: string) =>
    user?.communityMemberships.some(({ creator }) => creator.slug === creatorSlug)

export const hasUserSinglePurchasedLive = (user: BaseUser, live: BaseLive) =>
    live.attendees.some(({ attendeeId }) => attendeeId === user.id)

export const hasUserPurchasedCourse = (user: BaseUser, course: BaseCourse) =>
    course.purchases.some(({ purchaserId }) => purchaserId === user.id)

export const hasUserSinglePurchasedRecording = (user: BaseUser, live: BaseLive) =>
    live.recording?.purchasers.some(({ purchaserId }) => purchaserId === user.id)

export const getUserAccessTypeForLive = (user: BaseUser, live: BaseLive): EContentAccessType => {
    if (isUserSubscribedToCreator(user, live.creator.id)) {
        return EContentAccessType.SUBSCRIBED
    }
    if (hasUserSinglePurchasedLive(user, live)) {
        return EContentAccessType.PURCHASED
    }
    return EContentAccessType.NONE
}

export const getUserAccessTypesForCourse = (
    user: BaseUser,
    course: BaseCourse,
): EContentAccessType[] => {
    const accessTypes = []
    if (isUserSubscribedToCreator(user, course.creator.id)) {
        accessTypes.push(EContentAccessType.SUBSCRIBED)
    }
    if (hasUserPurchasedCourse(user, course)) {
        accessTypes.push(EContentAccessType.PURCHASED)
    }
    return accessTypes
}

export const canUserAccessCourse = (user: BaseUser, course: BaseCourse): boolean => {
    const requirements = getCourseAccessRequirements(course)
    const accessTypes = getUserAccessTypesForCourse(user, course)
    for (const requirement of requirements) {
        if (!accessTypes.includes(requirement)) {
            return false
        }
    }
    return true
}

export const canUserAccessCourseLive = (
    user: BaseUser,
    course: BaseCourse,
    live: Live,
): boolean => {
    const canAccessCourse = canUserAccessCourse(user, course)

    if (!canAccessCourse) {
        return false
    }
    const coursePurchase = course.purchases.find(({ purchaserId }) => purchaserId === user.id)
    if (!coursePurchase) {
        return false
    }
    // If the live access tier is .First, then any purchase is sufficient
    if (live.accessTier === ECourseAccessTier.First) {
        return true
    }
    // If the access tier is higher, then the tier type has to match
    return live.accessTier === coursePurchase.accessTier
}

export const canUserAccessLive = (user: BaseUser, live: BaseLive): boolean => {
    const accessType = getUserAccessTypeForLive(user, live)
    return [EContentAccessType.PURCHASED, EContentAccessType.SUBSCRIBED].includes(accessType)
}

export const getUserAccessTypeForRecording = (
    user: BaseUser,
    live: BaseLive,
): EContentAccessType => {
    if (isUserSubscribedToCreator(user, live.creator.id)) {
        return EContentAccessType.SUBSCRIBED
    }
    const purchase = live.recording?.purchasers.find(({ purchaserId }) => purchaserId === user.id)
    if (purchase) {
        if (!purchase.expiresAt || dayjs(purchase.expiresAt).isAfter(new Date())) {
            return EContentAccessType.PURCHASED
        } else {
            return EContentAccessType.PURCHASE_EXPIRED
        }
    }
    return EContentAccessType.NONE
}

export const canUserAccessRecording = (user: BaseUser, live: BaseLive): boolean => {
    const accessType = getUserAccessTypeForRecording(user, live)
    return [EContentAccessType.PURCHASED, EContentAccessType.SUBSCRIBED].includes(accessType)
}

export const isCreatorSetup = (user: BaseUser): boolean =>
    !!(user.isCreator && user.slug && user.country && user.firstName && user.stripeConnectId)

export const sortLives = (orderType: "asc" | "desc") => (a: Live, b: Live) => {
    const aStart = dayjs(a.startDate).unix()
    const bStart = dayjs(b.startDate).unix()

    if (orderType === "asc") {
        return aStart - bStart
    } else {
        return bStart - aStart
    }
}

export const sortCourses = (sortBy: "asc" | "desc") => (a: BaseCourse, b: BaseCourse) => {
    const aStart = dayjs(a.sessions[0].startDate).unix()
    const bStart = dayjs(b.sessions[0].startDate).unix()

    if (sortBy === "asc") {
        return aStart - bStart
    } else {
        return bStart - aStart
    }
}

export const hasSnippetPlatformSubscription = (
    user: BaseUser,
    requiredPlan?: EPlatformSubscriptionType,
) => {
    if (user.platformSubscriptions.length === 0) {
        return false
    } else {
        for (const sub of user.platformSubscriptions) {
            if (
                [
                    EPlatformSubscriptionType.Pro,
                    EPlatformSubscriptionType.Starter,
                ].includes(sub.plan.name as EPlatformSubscriptionType)
            ) {
                if (requiredPlan) {
                    return sub.plan.name === requiredPlan
                }
                return true
            }
        }
    }
}

export const generateUserMagicClaimToken = async (email: string, userId: string) => {
    const jwt = await import("jsonwebtoken")
    const token = jwt.sign({ email, userId }, process.env.LIVELINK_API_KEY!)
    return token
}

export const canUserSubmitNewVideo = (user: BaseUser) => {
    const allTasksErrored = user?.snippetTasks.every(
        (t) => t.errorMessage !== null || t.errorTemplate !== null,
    )

    const hasNoCompletedTasks = !user?.snippetTasks.length || allTasksErrored
    const hasPlatformSubscription = hasSnippetPlatformSubscription(user)
    const extraMinutes = getAdditionalMinutesForUser(user)
    const isFreeUsageAvailable = user?.usedMinutes! < _c.DEFAULT_TOTAL_MINUTES + extraMinutes

    if (hasNoCompletedTasks || (!hasPlatformSubscription && isFreeUsageAvailable)) {
        return true
    } else if (hasPlatformSubscription) {
        if (allTasksErrored) {
            return true
        }
        const [subscription] = user?.platformSubscriptions
        const { usedMinutes } = user
        const { totalMinutes } = subscription.plan
        if (usedMinutes && totalMinutes) {
            if (usedMinutes >= totalMinutes + extraMinutes) {
                return false
            }
        }
        return true
    }
    return false
}

export const getUserVideoDurationCriteria = (user: BaseUser, videoDuration: number) => {
    const isSubscribed = hasSnippetPlatformSubscription(user)
    if (!isSubscribed) {
        return {
            isExceeded: videoDuration > _c.SNIPPET_UPPER_DURATION_LIMIT_FREE,
            limit: _c.SNIPPET_UPPER_DURATION_LIMIT_FREE,
        }
    }
    const isStarter = hasSnippetPlatformSubscription(user, EPlatformSubscriptionType.Starter)
    if (isStarter) {
        return {
            isExceeded: videoDuration > _c.SNIPPET_UPPER_DURATION_LIMIT_STARTER,
            limit: _c.SNIPPET_UPPER_DURATION_LIMIT_STARTER,
        }
    }
    return {
        isExceeded: videoDuration > _c.SNIPPET_UPPER_DURATION_LIMIT_PRO,
        limit: _c.SNIPPET_UPPER_DURATION_LIMIT_PRO,
    }
}

export const getUserFileSizeCriteria = (user: BaseUser, fileSize: number) => {
    const isSubscribed = hasSnippetPlatformSubscription(user)
    if (!isSubscribed) {
        return {
            isExceeded: fileSize > _c.SNIPPET_VIDEO_SIZE_LIMIT_FREE,
            limit: _c.SNIPPET_VIDEO_SIZE_LIMIT_FREE,
        }
    }
    const isStarter = hasSnippetPlatformSubscription(user, EPlatformSubscriptionType.Starter)
    if (isStarter) {
        return {
            isExceeded: fileSize > _c.SNIPPET_VIDEO_SIZE_LIMIT_STARTER,
            limit: _c.SNIPPET_VIDEO_SIZE_LIMIT_STARTER,
        }
    }
    return {
        isExceeded: fileSize > _c.SNIPPET_VIDEO_SIZE_LIMIT_PRO,
        limit: _c.SNIPPET_VIDEO_SIZE_LIMIT_PRO,
    }
}

export const getUserBrandCountCriteria = (user: BaseUser, brandCount: number) => {
    const isSubscribed = hasSnippetPlatformSubscription(user)
    if (!isSubscribed) {
        return {
            isExceeded: brandCount > _c.SNIPPET_BRANDS_COUNT_LIMIT_FREE,
            limit: _c.SNIPPET_BRANDS_COUNT_LIMIT_FREE,
        }
    }
    const isStarter = hasSnippetPlatformSubscription(user, EPlatformSubscriptionType.Starter)
    if (isStarter) {
        return {
            isExceeded: brandCount > _c.SNIPPET_BRANDS_COUNT_LIMIT_STARTER,
            limit: _c.SNIPPET_BRANDS_COUNT_LIMIT_STARTER,
        }
    }
    return {
        isExceeded: false,
        limit: Infinity,
    }
}

export const getAdditionalMinutesForUser = (user: BaseUser): number => {
    if (user.additionalMinutes?.length > 0) {
        const claimedMinutes = user.additionalMinutes.filter((t) => t.expiresAt !== null)
        const activeMinutes = claimedMinutes.filter((t) => dayjs().isBefore(t.expiresAt))
        return sumNumbers(activeMinutes.map((t) => t.minutes)) ?? 0
    }
    return 0
}

export const getRemainingMinutesAllowance = (user: BaseUser) => {
    const extraMinutes = getAdditionalMinutesForUser(user)
    const [subscription] = user.platformSubscriptions
    const planMinutes = subscription?.plan?.totalMinutes ?? 0
    const usedMinutes = user.usedMinutes ?? 0
    return planMinutes + extraMinutes - usedMinutes
}

export const getDefaultBrandStyle = (user: BaseUser) => {
    if (user.brandStyles.length > 0) {
        return user.brandStyles.filter((bs) => !!bs.isDefault)[0]
    }
}

export const assertUserCanUpdateSubscription = (
    currentUser: BaseUser,
    subscription: PlatformSubscription | undefined,
) => {
    if (!currentUser!.stripeCustomerId) {
        throw new Error("No stripe customer found on user")
    }
    if (!subscription) {
        throw new Error("No subscription found with that ID")
    }
    if (subscription.userId !== currentUser!.id) {
        throw new Error("You do not have permission to cancel that subscription")
    }
}

const WhiteListedEmailsToRefresh = [
    "brad@livelink.vip",
    "mujavid@livelink.vip",
    "batuhan@livelink.vip",
    "damien@livelink.vip",
    "imdad@livelink.vip",
    "lorelie@livelink.vip",
    "ismail@livelink.vip",
    "ismailj@livelink.vip",
]

export const isEmailWhitelistedToRefresh = (email: string) => {
    return WhiteListedEmailsToRefresh.includes(email)
}
